import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Avatar, Box, Button, Card, CardActions, CardContent, Fade, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@mui/material';
import GlobalModel from '../components/GlobalModal/GlobalModel';
import { UseStateManager } from '../Context/StateManageContext';

const AdminProfile = ({ onChange }) => {


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #ee2e24',
        boxShadow: 24,
        borderRadius: '8px',
        p: 4,
    };

    const [imageFile, setImageFile] = useState(null);
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImageFile(file);
    };


    const [logOutopen, setLogoutOpen] = useState(false)
    const handleLogoutClose = () => {
        setLogoutOpen(!logOutopen)
    }

    const [open, setOpen] = useState(false)
    const handleClose = () => {
        setOpen(!open)
    }

    const { currentUser, setCurrentUser } = UseStateManager();
    const navigate = useNavigate();

    const handleLogoutFunction = () => {
        sessionStorage.removeItem("user")
        setCurrentUser(null);
        navigate("/");
    }


    return (
        <div>
            <Typography variant='h3'>Admin Profile</Typography>
            <hr />
            {/* Personal Information */}
            <section>
                <Card>
                    <Typography p={2} variant='h5'>Personal Information</Typography>
                    <CardContent>
                        <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                            <Box sx={{ position: 'relative' }}>
                                <img style={{ width: '200px', height: '200px', borderRadius: '24px', marginLeft: '100px' }} alt="Remy Sharp"
                                    src={currentUser?.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'}
                                />
                                <Box sx={{ position: 'absolute', left: '100px', bottom: '-8px' }}>
                                    <IconButton onClick={() => document.getElementById('clickMe').click()} variant='text'><BorderColorIcon color='error' /></IconButton>
                                    <input id='clickMe' style={{ display: 'none' }} type="file" name='avatar' accept="image/*" onChange={onChange} />
                                </Box>
                            </Box>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <Typography fontWeight={700}>Name:</Typography>
                                            </TableCell>
                                            <TableCell>{currentUser?.name}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <Typography fontWeight={700}>Email:</Typography>
                                            </TableCell>
                                            <TableCell>{currentUser?.email}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <Typography fontWeight={700}>Role:</Typography>
                                            </TableCell>
                                            <TableCell>{currentUser?.role}</TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        {/* Security and Password Management */}

                        <Typography p={2} variant='h4' gutterBottom>Security and Password Management</Typography>

                        <CardActions>
                            <Button onClick={() => setOpen(true)} variant='contained'>Change Password</Button>
                            {/* Add other security-related options here */}

                            <Button variant='contained' onClick={() => setLogoutOpen(true)}>Logout</Button>
                        </CardActions>
                    </CardContent>
                </Card>

                {/* Add other personal information fields here */}
            </section>
            <GlobalModel
                open={open}
                onClose={handleClose}
                content={
                    <Fade in={open}>
                        <Box sx={style}>
                            <TextField
                                id="outlined-basic"
                                focused
                                fullWidth
                                label="Enter Email Id"
                                variant="outlined"
                                name='email'
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', gap: '5px', marginTop: 2 }}>
                                <Button variant='contained'>Send</Button>
                                <Button variant='outlined' onClick={handleClose}>Close</Button>
                            </Box>
                        </Box>
                    </Fade>
                }
            />
            <GlobalModel
                open={logOutopen}
                onClose={handleLogoutClose}
                content={
                    <Fade in={logOutopen}>
                        <Box sx={style}>
                            <Typography variant='h5'> Do you really want to logOut ?</Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', gap: '5px', marginTop: 2 }}>
                                <Button onClick={handleLogoutFunction} variant='contained'>Yes, LogOut</Button>
                                <Button variant='outlined' onClick={handleLogoutClose}>No</Button>
                            </Box>
                        </Box>
                    </Fade>
                }
            />
        </div>
    );
};

export default AdminProfile;

