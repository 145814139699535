import * as React from 'react';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Button, Card, CardActions, CardContent, Chip, Fade, Grid, Menu, MenuItem, styled } from '@mui/material';
import GlobalModel from '../GlobalModal/GlobalModel';
import { GetListOfKyc, MakeActionOnKyc } from '../../Store/Actions/AdminActions/kycActions';

export default function KycTable() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedRow, setSelectedRow] = React.useState("")

    const allKycList = useSelector(state => state.GetAllKycList)
    const actionResult = useSelector(state => state.MakeKycActionReducer)
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const DataWithID = (data) => {
        const newData = [];
        if (data !== undefined) {
            data.forEach((item, index) => {
                newData.push({ ...item, id: index });
            });
        } else {
            newData.push({ id: 0 });
        }
        return newData;
    };


    const rows = [
        {
            id: 1,
            name: 'Rajiv',
            currentStatus: 'rejected',
            emailId: 'xyz@gmail.com',
            addharNo: '15457598342585',
            panNo: 'NCJDHFK1165',
        },
        {
            id: 2,
            name: 'Rajiv',
            currentStatus: 'approved',
            emailId: 'xyz@gmail.com',
            addharNo: '15457598342585',
            panNo: 'NCJDHFK1165',
        },
        {
            id: 3,
            name: 'Rajiv',
            currentStatus: 'requested',
            emailId: 'xyz@gmail.com',
            addharNo: '15457598342585',
            panNo: 'NCJDHFK1165',
        },

    ];

    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        {
            field: 'name',
            headerName: 'Partner Name',
            width: 200,
            renderCell: (params) => (
                <div style={{ display: 'flex' }}>
                    {/* <img width={50} height={50} style={{ borderRadius: '50%' }} style={{ borderRadius: '50%' }} src={params.row.image} alt=".." /> */}
                    <div style={{ paddingLeft: '5px' }}>
                        <p>{params.row.name}</p>
                    </div>
                </div>
            ),
        },
        {
            field: 'email',
            headerName: 'Partner Email',
            width: 150,
            renderCell: (params) => (
                <div>
                    <p style={{ margin: '0 0px' }}>{params.row.email}</p>
                </div>
            ),
        },
        {
            field: 'addhaarData',
            headerName: 'Partner Addhaar',
            width: 350,
            renderCell: (params) => (
                <div style={{ padding: '10px 20px' }}>
                    <div >
                        <p style={{ margin: '0 0px' }}>UID: {params.row.aadharNo}</p>
                        <Button sx={{ ml: 2 }} variant='contained' onClick={() => handleImageOpen(params.row._id)} > View Addhar</Button>
                    </div>

                </div>
            ),
        },
        {
            field: 'panData',
            headerName: 'Partner Pan',
            width: 280,
            renderCell: (params) => (
                <div style={{ padding: '10px 0px' }}>
                    <div >
                        <p style={{ margin: '0 0px' }}>PAN: {params.row.panNo}</p>
                        <Button sx={{ ml: 2 }} variant='contained' onClick={() => handleImageOpenS(params.row._id)} > View Pan</Button>
                    </div>
                </div>
            ),
        },
        {
            field: 'CurrentStatus',
            headerName: 'Status',
            width: 200,
            renderCell: (params) => (
                <div style={{ padding: '10px 0px' }}>
                    {
                        params.row.isVerified === 'requested' ?
                            <Chip label={'Requested'} color={'info'} /> : params.row.isVerified === 'approved' ?
                                <Chip label={'Approved'} color={'success'} /> : params.row.isVerified === 'failed' ?
                                    <Chip label={'Rejected'} color={'error'} /> : null
                    }
                </div>
            ),
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 300,
            renderCell: (params) => (
                <div>
                    <LoadingButton variant='contained' onClick={() => dispatch(MakeActionOnKyc(params.row._id, "approved"))}>Approve</LoadingButton>
                    <LoadingButton variant='outlined' sx={{ m: 1 }} onClick={() => dispatch(MakeActionOnKyc(params.row._id, "failed"))}>Reject</LoadingButton>
                </div>
            ),
        },
    ];

    const FlippingCardContainer = styled(Card)(({ theme }) => ({
        width: 400,
        height: 300,
        position: 'relative',
        cursor: 'pointer',
    }));

    const CardFront = styled('div')(({ theme }) => ({
        zIndex: 2,
        transform: 'rotateY(0deg)',
        transition: 'transform 0.5s',
    }));

    const CardBack = styled('div')(({ theme }) => ({
        zIndex: 1,
        transform: 'rotateY(0deg)',
        transition: 'transform 0.5s',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    }));

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        borderRadius: '10px',
        boxShadow: 24,
        p: 2,
    };

    const [openImage, setOpenImage] = React.useState(false);
    const handleImageOpen = (id) => {
        setSelectedRow(id)
        setOpenImage(true);
    }
    const handleImageClose = () => setOpenImage(false);

    const [openImageS, setOpenImageS] = React.useState(false);
    const handleImageOpenS = (id) => {
        setSelectedRow(id)
        setOpenImageS(true);
    }
    const handleImageCloseS = () => setOpenImageS(false);

    const [isFlipped, setIsFlipped] = React.useState(false);

    const handleCardClick = () => {
        setIsFlipped(!isFlipped);
    };
    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(GetListOfKyc())

    }, [actionResult, ""])



    return (
        <div style={{ minheight: '100vh', width: '100%', marginTop: '10px' }}>


            <GlobalModel open={openImage} handleClose={handleImageClose} content={
                <Fade in={openImage}>
                    <Box sx={style}>
                        <Grid container justifyContent="center" alignItems="center">
                            <FlippingCardContainer onClick={handleCardClick}>
                                <CardFront style={{ display: isFlipped ? 'none' : 'block' }}>
                                    <CardContent>
                                        <img style={{ height: '200px', width: '400px' }} src={allKycList?.data.all?.find(x => x._id === selectedRow)?.aadharImg[0]} alt="frontSide" />
                                        <CardActions>
                                            <Button variant='contained' size="small" color="primary">
                                                Back Side
                                            </Button>

                                            <Button onClick={handleImageClose} variant='outlined' size="small" color="primary">
                                                Close
                                            </Button>
                                        </CardActions>
                                    </CardContent>
                                </CardFront>
                                <CardBack style={{ display: isFlipped ? 'block' : 'none' }}>
                                    <CardContent>
                                        <img style={{ height: '200px', width: '400px' }} src={allKycList?.data.all?.find(x => x._id === selectedRow)?.aadharImg[1]} alt="backSide" />
                                        <CardActions>
                                            <Button variant='contained' size="small" color="primary">
                                                Front Side
                                            </Button>
                                            <Button onClick={handleImageClose} variant='outlined' size="small" color="primary">
                                                Close
                                            </Button>
                                        </CardActions>
                                    </CardContent>
                                </CardBack>
                            </FlippingCardContainer>
                        </Grid>
                    </Box>
                </Fade>} />
            <GlobalModel open={openImageS} handleClose={handleImageCloseS} content={
                <Fade in={openImageS}>
                    <Box sx={style}>
                        <Grid container justifyContent="center" alignItems="center">
                            <FlippingCardContainer>
                                <CardFront>
                                    <CardContent>
                                        <img style={{ height: '200px', width: '400px' }} src={allKycList?.data.all?.find(x => x._id === selectedRow)?.panImg} alt="panCard" />
                                        <CardActions>
                                            <Button onClick={handleImageCloseS} variant='outlined' size="small" color="primary">
                                                Close
                                            </Button>
                                        </CardActions>
                                    </CardContent>
                                </CardFront>
                            </FlippingCardContainer>
                        </Grid>
                    </Box>
                </Fade>} />
            <DataGrid
                rows={DataWithID(allKycList?.data.all)}
                columns={columns}
                density="comfortable" // Set default density to "comfortable"
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                components={{
                    Toolbar: GridToolbar,
                }}
            />
        </div>
    );
}
