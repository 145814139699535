// component
import { Icon } from '@iconify/react';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Profile',
    path: '/dashboard/profile',
    icon: icon('ic_user'),
  },
  {
    title: 'Manage Hotels',
    // path: '/dashboard/add',
    icon: <Icon icon="ic:twotone-hotel" width="25" height="25" />,
    subItems: [
      {
        title: 'Add Hotel',
        path: '/dashboard/add',
        icon: icon('ic_add'),
      },
      {
        title: 'Hotel List',
        path: '/dashboard/list',
        icon: icon('ic_list'),
      },
    ],
  },
  {
    title: 'Manage Rooms',
    // path: '/dashboard/add',
    icon: <Icon icon="mdi:bed-double" width="25" height="25" />,
    subItems: [
      {
        title: 'Room Availablity',
        path: '/dashboard/roomAvailablity',
        icon: icon('ic_list'),
      },
      {
        title: 'Room Management',
        path: '/dashboard/roomManagment',
        icon: icon('ic_list'),
      },
    ],
  },
  {
    title: 'Manage Bookings',
    // path: '/dashboard/add',
    icon: <Icon icon="ph:stack-duotone" width="25" height="25" />,
    subItems: [
      {
        title: 'All Bookings',
        path: '/dashboard/booking',
        icon: icon('ic_list'),
      },
      {
        title: 'Hotel Bookings',
        path: '/dashboard/hotel-bookings',
        icon: icon('ic_list'),
      },
    ],
  },
  {
    title: 'Review & Rating',
    path: '/dashboard/review-rating',
    icon: <Icon icon="material-symbols:reviews-outline-sharp" width="25" height="25" />,
  },
  // {
  //   title: 'FAQ Management',
  //   // path: '/dashboard/add',
  //   icon: <Icon icon="wpf:faq" width="25" height="25" />,
  //   subItems: [
  //     {
  //       title: 'Add FAQ',
  //       path: '/dashboard/add-faq',
  //       icon: icon('ic_add'),
  //     },
  //     {
  //       title: 'Hotel wise FAQ',
  //       path: '/dashboard/hotel-wise-faq',
  //       icon: icon('ic_list'),
  //     },
  //   ],
  // },
  // {
  //   title: 'Manage Customer',
  //   path: '/dashboard/manageCustomer',
  //   icon: <Icon icon="solar:user-bold-duotone" width="25" height="25" />,
  // },
  // {
  //   title: 'Manage Bookings',
  //   path: '/dashboard/booking',
  //   icon: <Icon icon="ph:stack-duotone" width="25" height="25" />,
  // },
  // {
  //   title: 'Manage Offer',
  //   path: '/dashboard/manageOffer',
  //   icon: <Icon icon="ic:twotone-discount" width="25" height="25" />,
  // },
  // {
  //   title: 'Manage Coupons',
  //   path: '/dashboard/manageCoupons',
  //   icon: <Icon icon="iconamoon:discount-duotone" width="25" height="25" />,
  // },
  // {
  //   title: 'Regulate Payment',
  //   path: '/dashboard/regulatePayment',
  //   icon: <Icon icon="ic:twotone-payment" width="25" height="25" />,
  // },
  // {
  //   title: 'Promotional Activities',
  //   path: '/dashboard/404',
  //   icon: <Icon icon="icons8:advertising" width="25" height="25" />,
  // },
  {
    title: 'Notifications',
    path: '/dashboard/notification',
    icon: <Icon icon="iconamoon:notification-duotone" width="25" height="25" />
  },
  // {
  //   title: 'Manage Review & Comments',
  //   path: '/dashboard/manageReviewComment',
  //   icon: <Icon icon="ic:twotone-star" width="25" height="25" />,
  // }

];

export default navConfig;
