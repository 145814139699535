// Import required libraries and components
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SettingsIcon from '@mui/icons-material/Settings';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { UseStateManager } from '../../Context/StateManageContext';
import { useGoogle } from '../../Context/UseGoogleContext';
import { GetHotelRegister } from '../../Store/Actions/HotelActions/HotelCurdAction';
import { GetFileUploaded } from '../../Store/Actions/ImageUploadActions/UploadActions';
import { GetAllVendors } from '../../Store/Actions/VendorsActions/GetAllVendorAction';
import { Getfacilities } from '../../Store/Actions/VendorsActions/Getfacilities';
import WaitLoader from '../../utils/WaitLoader';
import HotelValidations from './validations/HotelValidations';

// Custom styles for the Step Connector
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient(95deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient(95deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

// Custom styles for the Step Icon
const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage: 'linear-gradient(136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage: 'linear-gradient(136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

// Custom Step Icon Component
function ColorlibStepIcon({ active, completed, className, icon }) {
  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <FileUploadIcon />,
    4: <VideoLabelIcon />,
    5: <VideoLabelIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

// Array of step labels
const steps = ['Property Detail', 'Rooms & Amenities', 'Upload Images', 'Upload Legal Documents', 'Privacy & Policy'];

// Main component
export default function ZeroHotelForm({ handleClose }) {
  const { address, addressData } = useGoogle();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedVendor, setSelectedVendor] = useState('');
  // State for managing the number of rooms and 'More' section
  const [addRooms, setAddRooms] = useState(0);
  const [rooms, setRooms] = useState([]);
  // State for managing the active step and form data
  const [activeStep, setActiveStep] = useState(0);
  const [selectedCity, setSlectedCity] = useState(null);
  const [formData, setFormData] = useState({ hotelImg: [], rooms: [] });
  const [terms, setTerms] = useState(false);
  const [sanitized, setSanitized] = useState(false);
  const [discrimination, setDiscrimination] = useState(false);
  const [validTrue, setValidTrue] = useState(false);
  const vendorList = useSelector((state) => state.GetAllVendorsReducer);
  const UploadedImage = useSelector((state) => state.UploadReducer);
  // hotel register reducer
  const hotelRegister = useSelector((state) => state.GetHotelAddReducers);
  const { currentUser, Loader, setLoader, roomList, ammenitiesList, propertyType, allFacilities } = UseStateManager();
  // Functions to add and delete rooms
  const handleAddRoom = () => {
    setAddRooms(addRooms + 1);
  };

  const handleDeleteRoom = () => {
    setAddRooms(addRooms - 1);
  };

  // Function to move to the next step
  const handleNext = () => {
    const data = HotelValidations.AddHotelStepOne(
      activeStep,
      formData,
      rooms,
      terms,
      sanitized,
      discrimination,
      validTrue
    );
    if (data.error) {
      toast(`${data.message}`);

      // toast.error(`${data.message}`, { backgroundColor: 'red' });
    } else {
      toast.success(`${data.message}`);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  // Function to move back to the previous step
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  // vendor list map
  const vendorListOptions = vendorList?.data?.data
    ?.filter((x) => x.kycVerified === true)
    ?.map((item, index) => ({
      label: item?.name,
      _id: item?._id,
    }));

  // Function to handle form field changes and update the form data
  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (files && files.length > 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: files[0],
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  // full form fiels of hotel add
  const HotelAddForm = {
    // Hotel Details
    vendorId: selectedVendor?._id,
    isAddedBy: currentUser?.role,
    hotelName: formData && formData.name ? formData.name : '',
    hotelType: formData && formData.propertyType ? formData.propertyType : '',
    hotelEmail: formData && formData.email ? formData.email : '',
    hotelMobileNo: formData && formData.mobile ? formData.mobile : '',
    locality: formData && formData.locality ? formData.locality : '',
    city: formData && formData.city ? formData.city : '',
    country: formData && formData.country ? formData.country : '',
    state: formData && formData.state ? formData.state : '',
    zipCode: formData && formData.zipcode ? formData.zipcode : '',
    address: formData && formData.address ? formData.address : '',
    location: {
      coordinates: [formData?.longitude, formData?.latitude],
    },
    // Hotel rooms info
    rooms,
    amenities: formData && formData.amenities ? formData.amenities : [],
    hotelCoverImg: formData && formData.hotelCoverImg ? formData.hotelCoverImg : '',
    hotelImages: formData && formData.hotelImg ? formData.hotelImg : [],
    checkOut: formData && formData.checkOutTime ? formData.checkOutTime : '',
    checkIn: formData && formData.checkIn ? formData.checkIn : '',
    // Legal Documents
    ownershipType: formData && formData.ownershipType ? formData.ownershipType : '',
    regNo: formData && formData.regNo ? formData.regNo : '',
    regDocumentImg: formData && formData.regImg ? formData.regImg : '',
    businessPan: formData && formData.panNo ? formData.panNo : '',
    businessPanImg: formData && formData.panImg ? formData.panImg : '',
    gstNo: formData && formData.gstNo ? formData.gstNo : '',
    gstImg: formData && formData.gstImg ? formData.gstImg : '',
    tinNo: formData && formData.tinNo ? formData.tinNo : '',
    tinImg: formData && formData.tinImg ? formData.tinImg : '',
    // Policy and privacy
    cancellationPrice: formData && formData.cancellationPrices ? formData.cancellationPrices : '',
    termsAndCondition: terms,
    hotelFullySanitized: sanitized,
    notSupportDiscrimination: discrimination,
    validAndTrueData: validTrue,
    // Hotel Bookings
    hotelBookings: [],
    isAdminApproved: true,
    // Sir hi jane
    hotelMapLink: encodeURIComponent(formData && formData?.hotelMapLink).toString(),
  };
  const TooltipData = (currentUser) => {
    return (
      <table>
        <tbody>
          <tr>
            <td>name</td>
            <td>{currentUser?.name}</td>
          </tr>
          <tr>
            <td>email</td>
            <td>{currentUser?.email}</td>
          </tr>
          <tr>
            <td>mobile No</td>
            <td>{currentUser?.mobileNo}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  // get File Upload
  const HandleGetFileUploaded = (e, id) => {
    setLoader(true);
    dispatch(GetFileUploaded(e, id)).then(() => {
      setLoader(false);
    });
  };

  const HotelFinalSubmit = (formdata, currentUserid) => {
    const data = HotelValidations.AddHotelStepOne(
      activeStep,
      formData,
      rooms,
      terms,
      sanitized,
      discrimination,
      validTrue
    );
    if (data.error) {
      toast(`${data.message}`);
    } else {
      setLoader(true);
      dispatch(GetHotelRegister(formdata, currentUserid)).then(() => {
        if (hotelRegister.data) {
          setLoader(false);
          handleClose();
        } else {
          toast.error('data not submit please try again');
        }
        navigate('/dashboard/list');
      });
    }
  };

  useEffect(() => {
    if (selectedCity !== null) {
      geocodeByPlaceId(selectedCity?.value?.place_id)
        .then((result) => {
          const { lat, lng } = result[0].geometry.location;
          const latitude = lat();
          const longitude = lng();
          const embededMapLInk = `https://www.google.com/maps/embed/v1/place?key=AIzaSyD_kgE_S3Nwf1IAamPa6D6ZyyazleBTrhI&q=place_id:${selectedCity?.value?.place_id}`;
          // const mapLink = `https://www.google.com/maps/place/?q=place_id:${selectedCity?.value?.place_id}`;
          const address = result[0].address_components;
          const postalCode = address.find((component) => component.types.includes('postal_code')).long_name;
          const country = address.find((component) => component.types.includes('country')).long_name;
          const state = address.find((component) => component.types.includes('administrative_area_level_1')).long_name;
          const city = address.find((component) => component.types.includes('locality')).long_name;
          const subLocaliites = [];
          for (let i = 0; i < address.length; i += 1) {
            // Change here: use < instead of <=
            if (address[i].types.includes('street_number')) {
              subLocaliites.push(address[i].long_name);
            } else if (address[i].types.includes('neighborhood')) {
              subLocaliites.push(address[i].long_name);
            } else if (address[i].types.includes('route')) {
              subLocaliites.push(address[i].long_name);
            } else if (address[i].types.includes('sublocality')) {
              subLocaliites.push(address[i].long_name);
            }
          }

          console.log(result);

          setFormData({
            ...formData,
            latitude,
            longitude,
            hotelMapLink: embededMapLInk,
            zipcode: postalCode,
            country,
            state,
            city,
            locality: subLocaliites.join(' , '),
            address: selectedCity?.label,
          });
          console.log(selectedCity);
        })
        .catch((error) => console.log(error));
    }
  }, [selectedCity]);

  useEffect(() => {
    dispatch(Getfacilities());
    if (currentUser.role === 'admin') {
      dispatch(GetAllVendors('name email mobileNo kycVerified'));
    } else {
      setSelectedVendor(currentUser);
    }
  }, []);
  useEffect(() => {
    if (UploadedImage.isSuccess === true) {
      if ('hotelImg' in UploadedImage.fileName) {
        console.log(UploadedImage);
        if (formData.hotelImg.includes(UploadedImage.fileName.hotelImg) === false) {
          formData.hotelImg.push(UploadedImage.fileName.hotelImg);
        }
      } else {
        setFormData({ ...formData, ...UploadedImage.fileName });
      }
    }
  }, [UploadedImage]);

  // push the location data in the formdata
  useEffect(() => {
    setFormData({
      ...formData,
      ...addressData,
      address,
    });
  }, [address]);

  const getFormFields = (step) => {
    switch (step) {
      case 0:
        return (
          // Step 1: Property Details
          <>
            {/* Property Name */}
            <Grid container spacing={2}>
              {currentUser?.role === 'admin' ? (
                <Grid item xs={4}>
                  <Tooltip
                    title={
                      selectedVendor
                        ? TooltipData(vendorList?.data?.data?.find((item) => item._id === selectedVendor?._id))
                        : null
                    }
                    placement="right"
                    followCursor
                    arrow
                  >
                    {vendorListOptions ? (
                      <Autocomplete
                        options={vendorListOptions}
                        value={selectedVendor}
                        onChange={(value, newValue) => {
                          setSelectedVendor(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText="Note:Please Check The Kyc Of the hotel Partner If the Hotel Partner is not Showing"
                            label="Select Hotel Partner"
                            variant="outlined"
                            required
                          />
                        )}
                      />
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <ClipLoader loading="true" color="red" />
                      </div>
                    )}
                  </Tooltip>
                </Grid>
              ) : (
                <Grid item xs={4}>
                  <Tooltip title={TooltipData(selectedVendor)} placement="right" followCursor arrow>
                    <TextField
                      id="vendor"
                      name="vendor"
                      value={selectedVendor?.name}
                      // onChange={handleChange}
                      disabled
                      fullWidth
                      helperText="Your Account"
                    />
                  </Tooltip>
                </Grid>
              )}
              <Grid item xs={4}>
                <TextField
                  id="name"
                  name="name"
                  value={formData.name || ''}
                  onChange={handleChange}
                  fullWidth
                  required
                  label="Property Name"
                  helperText="Type your property Name"
                />
              </Grid>
              <Grid item xs={4}>
                {/* Property Type */}
                <TextField
                  fullWidth
                  id="outlined-select-currency"
                  select
                  label="Property Type"
                  required
                  defaultValue="EUR"
                  value={formData.propertyType || ''}
                  name="propertyType"
                  onChange={handleChange}
                  helperText="What type of property you want to onboard"
                >
                  {propertyType.data.map((item, index) => (
                    <MenuItem key={index} value={item._id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            {/* Current Location */}
            <div style={{ textAlign: 'center' }}>
              <Grid container my={1} spacing={2}>
                <Grid item xs={3}>
                  {/* Official Email */}
                  <TextField
                    id="email"
                    name="email"
                    label="Official Email"
                    required
                    value={formData.email || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  {/* Official Mobile Number */}
                  <TextField
                    id="mobile"
                    name="mobile"
                    label="Official Mobile Number"
                    value={formData.mobile || ''}
                    required
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  {/* Official Email */}
                  <GooglePlacesAutocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                    apiOptions={{ language: 'en', region: 'in', libraries: 'places' }}
                    selectProps={{
                      value: selectedCity,
                      onChange: setSlectedCity,
                      placeholder: 'Enter and Select Hotel Location*',
                      styles: {
                        input: (provided) => ({
                          ...provided,
                          padding: '10px',
                        }),
                        option: (provided) => ({
                          ...provided,
                          color: '#ee2e24',
                        }),
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container my={1} spacing={2}>
                <Grid item xs={8}>
                  <TextField
                    id="mapLink"
                    name="hotelMapLink"
                    label="Map Link"
                    required
                    helperText="Note: if the link show incorrect location paste your business link from google"
                    value={formData.hotelMapLink || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  {/* Official Mobile Number */}
                  <TextField
                    id="longitude"
                    name="longitude"
                    className="zindex0"
                    required
                    label="Longitude"
                    value={formData.longitude || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  {/* Official Mobile Number */}
                  <TextField
                    id="latitude"
                    name="latitude"
                    className="zindex0"
                    required
                    label="Latitude"
                    value={formData.latitude || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              {/* Street / Locality and City */}
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="locality"
                    name="locality"
                    className="zindex0"
                    required
                    label="Street / Locality"
                    value={formData.locality || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="city"
                    name="city"
                    className="zindex0"
                    required
                    label="City"
                    value={formData.city || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              {/* Country and Pin Code */}
              <Grid container mt={1} spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    id="country"
                    name="country"
                    label="Country"
                    required
                    value={formData.country || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="state"
                    name="state"
                    label="State"
                    required
                    value={formData.state || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    id="zipcode"
                    name="zipcode"
                    type="number"
                    label="Pin Code"
                    required
                    value={formData.zipcode || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </div>
            <div>
              <Grid>
                <TextField
                  id="address"
                  className="zindex0"
                  name="address"
                  required
                  label="Address"
                  value={formData.address || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </div>
          </>
        );
      case 1:
        return (
          // Step 2: Rooms & Amenities
          <>
            {/* Add Room Button */}
            <Button variant="contained" onClick={handleAddRoom}>
              Add Room
            </Button>
            <div>
              {/* Render rooms based on the 'addRooms' state */}
              {[...Array(addRooms)].map((_, index) => (
                <Grid container mt={1} spacing={2} key={index}>
                  <Grid item xs={2}>
                    {/* No Of Room */}
                    <TextField
                      id="counts"
                      name={`noOfRoom${index}`}
                      type="number"
                      fullWidth
                      value={rooms[index] === undefined ? '' : rooms[index].counts}
                      label="No Of Room"
                      onChange={(e) => {
                        const updatedRooms = [...rooms];
                        updatedRooms[index] = { ...updatedRooms[index], counts: e.target.value };
                        setRooms(updatedRooms);
                      }}
                    />
                  </Grid>
                  <Grid item xs={addRooms > 1 ? 2 : 2}>
                    {/* Select Room Type */}
                    <FormControl fullWidth>
                      <InputLabel id={`select-label2-${index}`}>Select Room Type</InputLabel>
                      <Select
                        labelId={`select-label2-${index}`}
                        id={`select2-${index}`}
                        name={`select2-${index}`}
                        value={rooms[index] === undefined ? '' : rooms[index].roomType}
                        onChange={(e) => {
                          const updatedRooms = [...rooms];
                          updatedRooms[index] = { ...updatedRooms[index], roomType: e.target.value };
                          setRooms(updatedRooms);
                        }}
                      >
                        {roomList.data.map((item, index) => (
                          <MenuItem key={index} value={item._id}>
                            {item.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={1}>
                    {/* No Of Room */}
                    <TextField
                      id="counts"
                      name={`noOfRoom${index}`}
                      type="number"
                      fullWidth
                      value={rooms[index] === undefined ? '' : rooms[index].price}
                      label="Price Per Room / Night"
                      onChange={(e) => {
                        const updatedRooms = [...rooms];
                        updatedRooms[index] = { ...updatedRooms[index], price: e.target.value };
                        setRooms(updatedRooms);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    mx={2}
                    sx={{ border: '1px solid gray', borderRadius: '10px', paddingTop: '0 !important' }}
                  >
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography pb={2} variant="h6" style={{ color: 'red' }}>
                          Amenities *
                        </Typography>
                        {roomList.data
                          ?.find((x) => rooms[index]?.roomType === x._id)
                          ?.amenties.map((amenity) => (
                            <Typography variant="subtitle2">
                              {ammenitiesList.data?.find((y) => y._id === amenity)?.title}{' '}
                            </Typography>
                          ))}
                      </Grid>
                      <Grid item xs={6}>
                        <Typography pb={2} variant="h6" style={{ color: 'red' }}>
                          Facilities *
                        </Typography>
                        {roomList.data
                          ?.find((x) => rooms[index]?.roomType === x._id)
                          ?.includeFacilities.map((facility) => (
                            <Typography variant="subtitle2">
                              {allFacilities.data?.find((y) => y._id === facility)?.title}{' '}
                            </Typography>
                          ))}
                      </Grid>
                    </Grid>
                  </Grid>

                  {addRooms > 1 && (
                    // Delete Room Button (visible if more than one room)
                    <Grid item xs={1}>
                      <IconButton onClick={handleDeleteRoom} sx={{ mt: 1 }}>
                        <DeleteForeverIcon color="error" />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              ))}
            </div>
            <h6 style={{ color: 'red' }}>
              *Note : You can add your additonal Amenities or Facilities in your room management
            </h6>
          </>
        );
      case 2:
        return (
          // step 3: Upload images
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {/* Upload Hotel Cover Image */}
              <label htmlFor="uploadCoverimage">
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="hotelCoverImg"
                  type="file"
                  onChange={(e) => HandleGetFileUploaded(e, currentUser._id)}
                  name="hotelCoverImg"
                />
                <div>
                  <Button
                    variant="contained"
                    component="span"
                    onClick={() => {
                      document.querySelector('#hotelCoverImg').click();
                    }}
                  >
                    Upload Hotel Cover Image
                  </Button>
                  <p>
                    <b>Please upload only jpeg, jpg, png files.</b>
                  </p>
                </div>
              </label>
              <div>
                <img
                  style={{ width: '250px' }}
                  src={
                    formData?.hotelCoverImg ||
                    'https://t4.ftcdn.net/jpg/05/07/58/41/360_F_507584110_KNIfe7d3hUAEpraq10J7MCPmtny8EH7A.jpg'
                  }
                  alt="hotelCoverImg"
                />
              </div>
            </div>
            <div>
              {/* Upload Multiple Hotel Images */}
              <label htmlFor="uploadCoverimage">
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="hotelImg"
                  type="file"
                  onChange={(e) => HandleGetFileUploaded(e, currentUser._id)}
                  name="hotelImg"
                  multiple // Add the "multiple" attribute to allow multiple file selection
                />
                <Button
                  color="primary"
                  variant="contained"
                  disabled={formData?.hotelImg.length === 10 && true}
                  component="span"
                  onClick={() => {
                    document.querySelector('#hotelImg').click();
                  }}
                >
                  Upload Multiple Hotel Images
                </Button>
                <p>
                  <b>Please upload hotel images one by one, and ensure they are in JPEG, JPG, or PNG format.</b>
                </p>
              </label>
              {/* <img style={{ width: '250px' }} src={item} alt={`${index}`} /> */}
              <div style={{ marginTop: '10px' }}>
                <Slider
                  {...sliderSettings}
                  slidesToShow={formData?.hotelImg.length >= 3 ? 3 : formData?.hotelImg.length}
                >
                  {formData?.hotelImg.map((item, index) => (
                    <div key={index}>
                      <img style={{ width: '250px', height: '180px' }} src={item} alt={`${index}`} />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </>
        );
      case 3:
        return (
          // Step 3: Upload Legal Documents
          <>
            <div>
              {/* Ownership Type and Registration Number */}
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="select-label4">Ownership Type</InputLabel>
                    <Select
                      labelId="select-label4"
                      id="ownershipType"
                      name="ownershipType"
                      required
                      value={formData.ownershipType || ''}
                      onChange={handleChange}
                    >
                      <MenuItem value="owned">Owned</MenuItem>
                      <MenuItem value="rented">Rented</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="regNo"
                    name="regNo"
                    label="Registry No., Electricity bill No."
                    required
                    type="text"
                    fullWidth
                    value={formData.regNo || ''}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {/* Upload Property Ownership Document */}
              <label htmlFor="regImg">
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="regImg"
                  type="file"
                  onChange={(e) => {
                    HandleGetFileUploaded(e, currentUser._id);
                  }}
                  name="regImg"
                />
                <Button variant="contained" component="span">
                  Upload Property Ownership Document*
                </Button>
                <b> Please upload only jpeg, jpg, png files.</b>
              </label>
              {formData.file1 && <p>File 1: {formData.file1.name}</p>}
            </div>
            {/* Business Pan Card No. */}
            <TextField
              id="panNo"
              name="panNo"
              label="Business Pan Card No."
              type="text"
              value={formData.panNo || ''}
              onChange={handleChange}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {/* Upload Business Pan Card */}
              <label htmlFor="panImg">
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="panImg"
                  type="file"
                  onChange={(e) => {
                    HandleGetFileUploaded(e, currentUser._id);
                  }}
                  name="panImg"
                />
                <Button variant="contained" component="span">
                  Upload Business Pan Card
                </Button>
                <b> Please upload only jpeg, jpg, png files.</b>
              </label>
              {formData.file2 && <p>Aadhar Front side {formData.file2.name}</p>}
            </div>
            {/* GST No. */}
            <TextField
              id="gstNo"
              name="gstNo"
              label="GST No."
              type="text"
              value={formData.gstNo || ''}
              onChange={handleChange}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {/* Upload GST Document */}
              <label htmlFor="gstImg">
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="gstImg"
                  type="file"
                  onChange={(e) => {
                    HandleGetFileUploaded(e, currentUser._id);
                  }}
                  name="gstImg"
                />
                <Button variant="contained" component="span">
                  Upload GST Document
                </Button>
                <b> Please upload only jpeg, jpg, png files.</b>
              </label>
              {formData.file3 && <p>Aadhar Back side: {formData.file3?.name}</p>}
            </div>
          </>
        );
      case 4:
        return (
          // Step 4: Privacy & Policy
          <>
            <div>
              {/* Check-in and Check-out timings */}
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="checkInTime"
                    name="checkIn"
                    label="Check-in"
                    value={formData.checkIn || ''}
                    onChange={handleChange}
                    type="time"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="checkOutTime"
                    name="checkOutTime"
                    label="Check-out"
                    type="time"
                    value={formData.checkOutTime || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  {/* Cancellation Prices / Charges */}
                  <TextField
                    id="cancellationPrices"
                    name="cancellationPrices"
                    label="Cancellation Prices / Charges ( in % )"
                    type="number"
                    placeholder="10%"
                    fullWidth
                    value={formData.cancellationPrices || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  {/* Checkbox: I Accept Hotelio Term & Conditions */}
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        value={terms}
                        onChange={() => {
                          setTerms(!terms);
                        }}
                      />
                    }
                    label="I Accept Hotelio Term & Conditions"
                  />
                </Grid>
                <Grid item xs={6}>
                  {/* Checkbox: I Accept My Hotel is fully Sanitized */}
                  <FormControlLabel
                    required
                    control={<Checkbox value={sanitized} onChange={() => setSanitized(!sanitized)} />}
                    label="I Accept My Hotel is fully Sanitized"
                  />
                </Grid>
                <Grid item xs={6}>
                  {/* Checkbox: I Accept We Not Support Discrimination */}
                  <FormControlLabel
                    required
                    control={<Checkbox value={discrimination} onChange={() => setDiscrimination(!discrimination)} />}
                    label="I Accept We Not Support Discrimination"
                  />
                </Grid>
                <Grid item xs={6}>
                  {/* Checkbox: I Accept all information given by me is valid & True */}
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        value={validTrue}
                        onChange={() => {
                          setValidTrue(!validTrue);
                        }}
                      />
                    }
                    label="I Accept all information given by me is valid & True"
                  />
                </Grid>
              </Grid>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const handleOnClickStep = () => {};

  return (
    // Main Form Component
    <>
      {/* Final Congratulatory Message (displayed when all steps are completed) */}
      {activeStep === steps.length ? (
        <div style={{ display: 'grid', placeItems: 'center' }}>
          <CheckCircleIcon color="success" sx={{ fontSize: '10rem' }} />
          <p>
            "Hey, congratulations! You have just completed all the steps. We will send your details to Hotelio, and
            after a couple of days, your hotel will be confirmed if all your details are true. Thank you!"
          </p>
        </div>
      ) : (
        // Form Steps and Navigation Buttons
        <Stack sx={{ width: '100%' }} spacing={4}>
          <WaitLoader Loading={Loader} />
          <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
            {/* Render Steps */}
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {/* Form */}
          <form onSubmit={handleNext}>
            <Stack spacing={2}>
              {/* Render the appropriate form fields based on the current step */}
              {getFormFields(activeStep)}
              {/* Navigation Buttons */}
              <Stack direction="row" justifyContent="space-between">
                <Button variant="contained" onClick={activeStep === 0 ? handleClose : handleBack}>
                  {activeStep === 0 ? 'Cancel' : 'Back'}
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  onClick={
                    activeStep === steps.length - 1
                      ? () => {
                          HotelFinalSubmit(HotelAddForm, currentUser._id);
                        }
                      : handleNext
                  }
                  disabled={!formData || Object.values(formData).length === 0}
                  color="primary"
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>
      )}
    </>
  );
}
