import { BlockBlobClient } from '@azure/storage-blob';
import axios from 'axios';
import Swal from 'sweetalert2';
import * as constant from '../../Constants/UploadConstants/UploadConstants';
import instance from '../../../utils/_utils';

async function GetSasUrl(fileName) {
  try {
    const res = await instance.get(`/util/azure/getsasurl?fileName=${fileName}`);
    return res.data.sasUrl;
  } catch (error) {
    console.error('Error while fetching signed URL:', error);
    throw error;
  }
}

// export const getImageSignedUrl = async (key) => {
//   try {
//     const queryUrl = new URL(`/util/s3/signedurl`);
//     queryUrl.searchParams.set('key', key);
//     const response = await instance.get(queryUrl.toString());

//     if (response.status === 200) {
//       return response.data.url;
//     }

//     throw new Error('Failed to get signed URL');
//   } catch (error) {
//     // Handle errors, e.g., log or rethrow as needed
//     console.error('Error while fetching signed URL:', error);
//     throw error;
//   }
// };

// get Documents Upload

// export const GetFileUploaded = (e, userId) => {
//     return async (dispatch) => {
//         dispatch({ type: constant.UPLOAD_API_LOADING })
//         const file = e.target.files[0]

//         // create formdata
//         const fd = new FormData()
//         fd.append("myfile", file)

//         try {
//             const isResponse = await axios.post(`${API_URL}/util/uploadfile/${userId}`, fd)
//             if (isResponse.status === 200) {
//                 dispatch({ type: constant.UPLOAD_API_SUCCESS, payload: isResponse.data, field: e.target.name })
//                 Swal.fire({
//                     title: 'Image Uploaded!',
//                     imageUrl: `${API_URL}/uploads/${isResponse.data.fileName}`,
//                     imageWidth: 400,
//                     imageHeight: 200,
//                     imageAlt: 'Uploaded',
//                 })
//             }
//         } catch (error) {
//             dispatch({ type: constant.UPLOAD_API_ERROR })
//             Swal.fire({
//                 title: "Image Upload Failed",
//                 text: "Image upload failed . Please try Again",
//                 icon: "error"
//             })
//         }
//     }
// }

export const GetFileUploaded = (e, currentUserId) => {
  return async (dispatch) => {
    dispatch({ type: constant.UPLOAD_API_LOADING });

    const fileName = e.target.files[0];
    const sasUrl = await GetSasUrl(`${currentUserId}/${fileName.name}`);
    if (sasUrl) {
      const blockBlobClient = new BlockBlobClient(sasUrl);
      const uploadBlobResponse = await blockBlobClient.uploadData(fileName, {
        blobHTTPHeaders: { blobContentType: fileName.type },
      });
      if (uploadBlobResponse._response.status === 201) {
        const fileUrl = uploadBlobResponse._response.request.url.split('?')[0];
        const uploadResponce = {
          isSuccess: true,
          fileName,
          fileUrl,
        };
        dispatch({ type: constant.UPLOAD_API_SUCCESS, payload: uploadResponce, field: { [e.target.name]: fileUrl } });
        Swal.fire({
          title: 'Image Uploaded!',
          imageUrl: fileUrl,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: 'Uploaded',
        });
      } else {
        console.log('s3 issue to upload image');
      }
    } else {
      dispatch({ type: constant.UPLOAD_API_ERROR });
      Swal.fire({
        title: 'Image Upload Failed',
        text: 'Image upload failed . Please try Again',
        icon: 'error',
      });
    }
  };
};
