import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Chip,
    Fade,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Rating,
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { ArrowBack } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import moment from 'moment/moment';
import Label from '../label';
import { UseStateManager } from '../../Context/StateManageContext';
import { GetFileUploaded } from '../../Store/Actions/ImageUploadActions/UploadActions';
import { GetAddPropertyType, GetAllPropertyType, RemovePropertyType, UpdatePropertyType } from '../../Store/Actions/AdminActions/allpropertyType';
import GlobalModel from '../GlobalModal/GlobalModel';
import { CreateRoomType, GetAllRoomType, RemoveAllRoomTypeAction, RemoveRoomTypeAction } from '../../Store/Actions/AdminActions/allRoomType';
import { GetAllAmenities, AddAmenitiesAction, RemoveAmenitiesAction, RemoveAllAmenitiesAction } from '../../Store/Actions/AdminActions/allamenities';
import { AddFacilitiesAction, GetAllFacilities, RemoveAllFacilitiesAction, RemoveFacilitiesAction } from '../../Store/Actions/AdminActions/allfacilities';


const ManageRoomAmenities = () => {

    const { currentUser } = UseStateManager();
    const imageUpload = useSelector((state) => state.UploadReducer)
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        borderRadius: '10px',
        boxShadow: 24,
        p: 4
    };

    const dispatch = useDispatch();
    const [inputs, setInputs] = useState({});
    const [loader, setLoader] = useState(false);
    const [activeTab, setActiveTab] = React.useState('');
    const [addPropertyType, setAddpropertyType] = useState(false)
    const handleAddpropertytype = () => setAddpropertyType(!addPropertyType)
    const handleClosepropertyType = () => {
        setUpdatepropertyType(false)
    }
    const [updatePropertyType, setUpdatepropertyType] = useState(null)

    const updateproperty = useSelector((state) => state.UpdatePropertyTypeReducers)

    const [updateProperyData, setUpdateProperyData] = useState({
        title: "",
        ratings: "",
        cover: "",
        discription: ""
    })
    const handleUpdatepropertytype = (id, formdata) => {
        setUpdateProperyData({ ...UpdatePropertyType, title: formdata.title, ratings: formdata.ratings, cover: formdata.cover, discription: formdata.discription })
        setUpdatepropertyType(id)
    }

    const PropertyList = useSelector((state) => state.AllPropertyTypeReducers);
    const addedData = useSelector((state) => state.AddPropertyTypeReducers);
    const AllAmenities = useSelector((state) => state.GetAllAmenities);
    const AllRoomTypeCategory = useSelector((state) => state.AllRoomTypeReducers);
    const AllFacilities = useSelector((state) => state.GetAllFacilities);

    useEffect(() => {
        dispatch(GetAllPropertyType());
        dispatch(GetAllAmenities());
        dispatch(GetAllRoomType());
        dispatch(GetAllFacilities());
    }, []);


    const handleRemoveProperty = async (id) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ee2e24',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!',
        });

        if (result.isConfirmed) {
            await dispatch(RemovePropertyType(id));
            Swal.fire({
                title: 'Deleted!',
                text: 'Your property has been deleted.',
                icon: 'success',
            });
        }
        dispatch(GetAllPropertyType());
    };




    const handleUpdateProperty = () => {

        dispatch(UpdatePropertyType(updateProperyData)).then(async () => {
            if (updateproperty?.data !== null) {
                Swal.fire({
                    icon: 'success',
                    title: 'Property Type Name Updated',
                    showConfirmButton: false,
                    timer: 1500,
                })
                await dispatch(GetAllPropertyType());

                handleUpdatepropertytype(null)
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to update property type. Please try again.',
                });
            }
        });

    };

    useEffect(() => {
        if (PropertyList.data !== null && updatePropertyType !== null) {
            const GetData = PropertyList?.data?.find((item) => item?._id === updatePropertyType)
            setInputs({ ...inputs, propertyTypeName: GetData?.title })
        }
    }, [updatePropertyType]);


    const tabs = {
        tab1: {
            label: 'Manage Room',
            imageSrc: '/assets/ManageRoom.webp',
        },
        tab2: {
            label: 'Manage Property Type',
            imageSrc: '/assets/PropertyType.webp',
        },
        tab3: {
            label: 'Manage Amenities',
            imageSrc: '/assets/ManageAmenities.webp',
        },
        tab4: {
            label: 'Manage Facilities',
            imageSrc: '/assets/ManageFacilities.webp',
        },
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        if (imageUpload.fileName) {
            setFormData({ ...formData, cover: imageUpload.fileName.cover })
        }
    }, [imageUpload])



    const [formData, setFormData] = useState({
        title: '',
        ratings: 0,
        cover: imageUpload?.fileName?.cover,
        discription: ''
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleUpdateData = (name, value) => {
        setUpdateProperyData({
            ...UpdatePropertyType,
            [name]: value
        });
    };

    const handleRatingChange = (newValue) => {
        setFormData((prevData) => ({
            ...prevData,
            ratings: newValue,
        }));
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        const formDataToSend = {
            title: formData.title,
            ratings: formData.ratings,
            cover: formData.cover,
            discription: formData.discription,
        };

        console.log(formDataToSend)

        try {
            await dispatch(GetAddPropertyType(formDataToSend));

            if (addedData.data) {
                Swal.fire({
                    icon: 'success',
                    title: 'Property Type Added',
                    showConfirmButton: false,
                    timer: 1500,
                });

                dispatch(GetAllPropertyType());
                handleAddpropertytype()
                setFormData({
                    title: '',
                    ratings: 0,
                    cover: null,
                    discription: ''
                })

            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to add property type. Please try again.',
                });
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle error, show a message or take appropriate action
        }
    };


    const [roomData, setRoomData] = useState({
        personAllowed: 4,
        includeFacilities: [],
        minPrice: '',
        maxPrice: '',
        amenties: [],
        title: ""
    })

    const handleInputChange = (e) => {
        setRoomData({
            ...roomData,
            [e.target.name]: e.target.value,
        });
    };

    const handleAddRoomCategory = () => {
        // Validate if any field is empty
        if (
            !roomData?.personAllowed ||
            !roomData?.includeFacilities ||
            !roomData?.minPrice ||
            !roomData?.maxPrice ||
            !roomData?.amenties ||
            !roomData?.title
        ) {
            // Fields are not filled, show error message
            Swal.fire({
                icon: 'error',
                title: 'Validation Error!',
                text: 'Please fill in all fields.',
            });
            return;
        }

        // All fields are filled, proceed with adding room category
        const data = {
            personAllowed: roomData?.personAllowed,
            includeFacilities: roomData?.includeFacilities,
            minPrice: roomData?.minPrice,
            maxPrice: roomData?.maxPrice,
            amenties: roomData?.amenties,
            title: roomData?.title,
        };

        dispatch(CreateRoomType(data))
            .then(() => {
                dispatch(GetAllRoomType());
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Room type category added successfully.',
                });

                // Clear form data
                setRoomData({
                    personAllowed: 4,
                    includeFacilities: '',
                    minPrice: '',
                    maxPrice: '',
                    amenties: '',
                    title: '',
                });
            })
            .catch((error) => {
                console.error('Error adding room category:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Failed to add room type category. Please try again.',
                });
            });
    };

    const handleRoomTypeCategoryDelete = async (facilityId) => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: 'You won\'t be able to revert this!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            });

            if (result.isConfirmed) {
                await dispatch(RemoveRoomTypeAction(facilityId));
                Swal.fire(
                    'Deleted!',
                    'Your room type category has been deleted.',
                    'success'
                );
                await dispatch(GetAllRoomType());
            }
        } catch (error) {
            console.error('Error deleting room type category:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Failed to delete room type category. Please try again.',
            });
        }
    };

    const handleRoomTypeDeleteAll = async () => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure Delete All Room type category?',
                text: 'You won\'t be able to revert this!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            });

            if (result.isConfirmed) {
                await dispatch(RemoveAllRoomTypeAction());
                Swal.fire(
                    'Deleted!',
                    'Your room type category have all been deleted.',
                    'success'
                );
                await dispatch(GetAllRoomType());
            }
        } catch (error) {
            console.error('Error deleting room type category:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Failed to delete room type category. Please try again.',
            });
        }
    };

    // all amenities related functions

    const [addAmenitiesData, setAddAmenitiesData] = useState({
        title: ''
    })

    const handleAmenitisInputChange = (e) => {
        setAddAmenitiesData({
            ...addAmenitiesData,
            [e.target.name]: e.target.value,
        });
    };

    const handleAddAmenitiRoomCategory = () => {
        const { title } = addAmenitiesData;

        // Validation check
        if (!title.trim()) {
            // Title is empty, show error message
            Swal.fire({
                icon: 'error',
                title: 'Validation Error!',
                text: 'Please add a title.',
            });
            return; // Don't proceed with the action
        }

        const data = {
            title,
        };

        dispatch(AddAmenitiesAction(data))
            .then(() => {
                dispatch(GetAllAmenities());
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Amenity added successfully.',
                });

                // Clear form data
                setAddAmenitiesData({
                    title: '',
                });
            })
            .catch((error) => {
                console.error('Error adding amenity:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Failed to add amenity. Please try again.',
                });
            });
    };

    const handleAmenitiesDelete = async (amenityId) => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: 'You won\'t be able to revert this!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            });

            if (result.isConfirmed) {
                await dispatch(RemoveAmenitiesAction(amenityId));
                Swal.fire(
                    'Deleted!',
                    'Your amenity has been deleted.',
                    'success'
                );
                await dispatch(GetAllAmenities());
            }
        } catch (error) {
            console.error('Error deleting amenity:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Failed to delete amenity. Please try again.',
            });
        }
    };

    const handleAmenitiesDeleteAll = async () => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure Delete All Amenities?',
                text: 'You won\'t be able to revert this!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            });

            if (result.isConfirmed) {
                await dispatch(RemoveAllAmenitiesAction());
                Swal.fire(
                    'Deleted!',
                    'Your amenities have all been deleted.',
                    'success'
                );
                await dispatch(GetAllAmenities());
            }
        } catch (error) {
            console.error('Error deleting amenity:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Failed to delete amenities. Please try again.',
            });
        }
    };

    // all facilities related functions    

    const [addFacilitiesData, setAddFacilitiesData] = useState({
        title: '',
        isPaid: false,
        price: ''
    })

    const handleFacilitiesInputChange = (e) => {
        setAddFacilitiesData({
            ...addFacilitiesData,
            [e.target.name]: e.target.value,
        });
    };

    const handleAddFacilityRoomCategory = () => {
        const { title } = addFacilitiesData;

        // Validation check
        if (!title.trim()) {
            // Title is empty, show error message
            Swal.fire({
                icon: 'error',
                title: 'Validation Error!',
                text: 'Please add a title.',
            });
            return; // Don't proceed with the action
        }

        const data = {
            title,
            isPaid: addFacilitiesData?.isPaid,
            price: addFacilitiesData?.price
        };

        dispatch(AddFacilitiesAction(data))
            .then(() => {
                dispatch(GetAllFacilities());
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Facility added successfully.',
                });

                // Clear form data
                setAddFacilitiesData({
                    title: '',
                });
            })
            .catch((error) => {
                console.error('Error adding amenity:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Failed to add facility. Please try again.',
                });
            });
    };

    const handleFacilitiesDelete = async (facilityId) => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: 'You won\'t be able to revert this!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            });

            if (result.isConfirmed) {
                await dispatch(RemoveFacilitiesAction(facilityId));
                Swal.fire(
                    'Deleted!',
                    'Your facility has been deleted.',
                    'success'
                );
                await dispatch(GetAllFacilities());
            }
        } catch (error) {
            console.error('Error deleting facility:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Failed to delete facility. Please try again.',
            });
        }
    };

    const handleFacilitiesDeleteAll = async () => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure Delete All Facilities?',
                text: 'You won\'t be able to revert this!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            });

            if (result.isConfirmed) {
                await dispatch(RemoveAllFacilitiesAction());
                Swal.fire(
                    'Deleted!',
                    'Your facilities have all been deleted.',
                    'success'
                );
                await dispatch(GetAllFacilities());
            }
        } catch (error) {
            console.error('Error deleting facilities:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Failed to delete facilities. Please try again.',
            });
        }
    };


    const renderTabs = () => {
        return Object.keys(tabs).map((tab) => (
            <Grid item xs={6} key={tab}>
                <button
                    onClick={() => handleTabClick(tab)}
                    style={{
                        position: 'relative',
                        margin: '5px',
                        width: '100%',
                        border: 'none',
                        background: 'transparent',
                    }}
                >
                    <img
                        style={{
                            border: '3px solid #ee2e24',
                            height: '300px',
                            width: '100%',
                            borderRadius: '12px',
                        }}

                        src={tabs[tab].imageSrc}
                        alt={tabs[tab].label}
                    />
                    <div style={{ position: 'absolute', bottom: '1px', width: '100%', textAlign: 'center' }}>
                        <Typography variant='h3' fontWeight={600}>
                            {tabs[tab].label}
                        </Typography>
                    </div>
                </button>
            </Grid>
        ));
    };


    return (
        <div>
            {activeTab ? (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <div>
                            <Button onClick={() => setActiveTab('')} variant='outlined'>
                                <ArrowBack /> Back
                            </Button>
                            <Typography py={2} variant='h5'>{tabs[activeTab].label}</Typography>
                        </div>
                    </Grid>
                    {activeTab === 'tab1' ? <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextField
                                    id='propertyType'
                                    name='title'
                                    value={roomData?.title}
                                    onChange={handleInputChange}
                                    fullWidth
                                    label='Enter Room Name' />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel id='selectAmenitiesLabel'>Select Amenities</InputLabel>
                                    <Select
                                        labelId='selectAmenitiesLabel'
                                        id='selectAmenities'
                                        value={roomData?.amenties}
                                        label='Select Amenities'
                                        name='amenties'
                                        onChange={handleInputChange}
                                        multiple
                                    >
                                        {
                                            AllAmenities?.data?.map((item, index) => (
                                                <MenuItem key={index} value={item._id}>{item?.title}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel id='selectFacilitiesLabel'>Select Facilities</InputLabel>
                                    <Select
                                        labelId='selectFacilitiesLabel'
                                        id='selectFacilities'
                                        value={roomData?.includeFacilities}
                                        name='includeFacilities'
                                        label='Select Facilities'
                                        onChange={handleInputChange}
                                        multiple
                                    >
                                        {
                                            AllFacilities?.data?.map((item, index) => (
                                                <MenuItem key={index} value={item._id}>{item?.title}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    name='minPrice'
                                    onChange={handleInputChange}
                                    value={roomData?.minPrice}
                                    type='number'
                                    fullWidth
                                    label='Enter Min Price'
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    name='maxPrice'
                                    onChange={handleInputChange}
                                    value={roomData?.maxPrice}
                                    type='number'
                                    fullWidth
                                    label='Enter Max Price'
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id='personAllowed'
                                    name='personAllowed'
                                    disabled
                                    value={roomData?.personAllowed}
                                    onChange={handleInputChange}
                                    type='number'
                                    fullWidth
                                    label='Person allowed' />
                            </Grid>
                            <Grid item xs={12} textAlign={'right'}>
                                <Button onClick={handleAddRoomCategory} variant='contained'>Save</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ gap: 1, display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography variant='h5'>Room Type Category List</Typography>
                                    <Button onClick={handleRoomTypeDeleteAll} variant='contained' >Remove All</Button>
                                </Box>
                                {
                                    AllRoomTypeCategory?.data?.map((item, index) => (
                                        <Box key={index}
                                            sx={{
                                                border: '3px solid #ee2e24',
                                                width: '100%',
                                                borderRadius: '12px',
                                                padding: '15px',
                                                margin: '5px'
                                            }}
                                        >
                                            <Typography py={1} variant='h5'>{item?.title}</Typography>
                                            <Grid container spacing={1}>
                                                <Grid item xs={4}>
                                                    <Label >Min Price</Label>
                                                    <Typography fontWeight={800} py={1} variant='body1'>{item?.minPrice}</Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Label fontWeight={800}>Max Price</Label>
                                                    <Typography fontWeight={800} py={1} variant='body1'>{item?.maxPrice}</Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Label fontWeight={800}>Person Allowed</Label>
                                                    <Typography fontWeight={800} py={1} variant='body1'>{item?.personAllowed}</Typography>
                                                </Grid>
                                            </Grid>
                                            <hr />
                                            <Grid container spacing={1}>
                                                <Grid item xs={6}>
                                                    <Label fontWeight={800}>Amenities</Label>
                                                    <Box sx={{ p: 2 }}>
                                                        <ul type='square'>
                                                            {
                                                                item?.amenties?.map((a, index) => (
                                                                    <li key={index}>{a?.title}</li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Label fontWeight={800}>Facilities</Label>
                                                    <Box sx={{ p: 2 }}>
                                                        <ul type='square'>
                                                            {
                                                                item?.includeFacilities?.map((f, index) => (
                                                                    <li key={index}>{f?.title}</li>
                                                                ))
                                                            }

                                                        </ul>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box sx={{ gap: 1, display: 'flex', justifyContent: 'end' }}>
                                                <Button onClick={() => handleRoomTypeCategoryDelete(item?._id)} variant='contained' >Remove</Button>
                                            </Box>
                                        </Box>
                                    ))
                                }
                            </Grid>
                        </Grid>
                    </Grid> : activeTab === 'tab2' ?
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div
                                    style={{
                                        border: '3px solid #ee2e24',
                                        width: '100%',
                                        borderRadius: '12px',
                                        padding: '15px'
                                    }}
                                >
                                    <Grid container spacing={1}>
                                        <Grid item xs={10}>
                                            <Typography py={1} variant='h5'>Property Type Names List</Typography>
                                        </Grid>
                                        <Grid item xs={2} sx={{ display: 'grid', placeItems: 'center' }}>
                                            <Button variant='contained' onClick={handleAddpropertytype}>Add Property Type</Button>
                                        </Grid>
                                        {Array.isArray(PropertyList?.data) && PropertyList.data.map((item, index) => (
                                            <Grid key={index} item xs={6}>
                                                <Box sx={{ p: 1 }} component={Paper}>
                                                    <ul>
                                                        <li style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '24px', color: '#ee2e24', border: '2px solid #ee2e24' }}>
                                                            <Box display={'flex'}>
                                                                <img style={{ borderTopLeftRadius: '24px', borderBottomLeftRadius: '24px' }} height={150} width={150} src={item?.cover || 'https://assets-global.website-files.com/5c6d6c45eaa55f57c6367749/65045f093c166fdddb4a94a5_x-65045f0266217.webp'} alt="altimg" />
                                                                <Box p={1}>
                                                                    <Typography variant='h4'>{item.title}</Typography>
                                                                    <Typography variant='body2'>{moment(item?.createdAt).format('D MMMM YYYY')}</Typography>
                                                                    <Typography variant='p'>{item?.discription || 'NA'}</Typography>
                                                                </Box>
                                                            </Box>
                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <IconButton onClick={() => handleUpdatepropertytype(item._id, item)} color='success'>
                                                                    <EditIcon fontSize='large' />
                                                                </IconButton>
                                                                <IconButton color='error' onClick={() => handleRemoveProperty(item._id)}>
                                                                    <DeleteForeverIcon fontSize='large' />
                                                                </IconButton>
                                                            </Box>
                                                        </li>
                                                    </ul>
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid> : activeTab === 'tab3' ?
                            <Grid container spacing={2}>
                                <Grid item xs={11}>
                                    <TextField
                                        id='title'
                                        name='title'
                                        type='text'
                                        fullWidth
                                        value={addAmenitiesData?.title}
                                        onChange={handleAmenitisInputChange}
                                        label='Enter Amenitie Name'
                                    />
                                </Grid>
                                <Grid item xs={1} sx={{ display: 'grid', placeItems: 'center' }}>
                                    <Button onClick={handleAddAmenitiRoomCategory} size='large' fullWidth variant='contained'>Save</Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <div
                                        style={{
                                            border: '3px solid #ee2e24',
                                            width: '100%',
                                            borderRadius: '12px',
                                            padding: '15px'
                                        }}
                                    >
                                        <Typography variant='h5'>Amenities list</Typography>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Box sx={{ py: 2 }}>
                                                    {
                                                        AllAmenities?.data?.map((item, index) => (
                                                            <Chip
                                                                color='primary'
                                                                variant="outlined"
                                                                icon={<CheckCircleOutlineIcon />}
                                                                key={index}
                                                                label={item?.title}
                                                                sx={{ m: 1 }}
                                                                onDelete={() => handleAmenitiesDelete(item?._id)}
                                                            />
                                                        ))
                                                    }
                                                    <Box sx={{ gap: 1, display: 'flex', justifyContent: 'end' }}>
                                                        <Button onClick={handleAmenitiesDeleteAll} variant='contained' >Remove All</Button>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid> : activeTab === 'tab4' &&
                            <Grid container spacing={2}>
                                <Grid item xs={addFacilitiesData?.isPaid === 'true' ? 3 : 4}>
                                    <TextField
                                        name='title'
                                        type='text'
                                        value={addFacilitiesData.title}
                                        fullWidth
                                        onChange={handleFacilitiesInputChange}
                                        label='Enter Facility Name'
                                    />
                                </Grid>
                                <Grid item xs={addFacilitiesData?.isPaid === 'true' ? 3 : 4}>
                                    <Select
                                        fullWidth
                                        labelId='selectFacilitiesLabel'
                                        id='isPaid'
                                        name='isPaid'
                                        value={addFacilitiesData?.isPaid}
                                        label='Facility Type'
                                        onChange={handleFacilitiesInputChange}
                                    >
                                        <MenuItem value={"false"}>Free Facility</MenuItem>
                                        <MenuItem value={"true"}>Paid Facility</MenuItem>
                                    </Select>


                                </Grid>
                                {addFacilitiesData?.isPaid === 'true' ? (
                                    <Grid item xs={3}>
                                        <TextField
                                            id='price'
                                            name='price'
                                            type='number'
                                            value={addFacilitiesData?.price}
                                            fullWidth
                                            onChange={handleFacilitiesInputChange}
                                            label='Enter Facility Price'
                                        />
                                    </Grid>
                                ) : null}
                                <Grid item xs={3} sx={{ display: 'grid', placeItems: 'center' }}>
                                    <Button size='large' onClick={handleAddFacilityRoomCategory} fullWidth variant='contained'>Save</Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <div
                                        style={{
                                            border: '3px solid #ee2e24',
                                            width: '100%',
                                            borderRadius: '12px',
                                            padding: '15px'
                                        }}
                                    >
                                        <Box sx={{ gap: 1, display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography variant='h5'>Facilities list</Typography>
                                            <Button onClick={handleFacilitiesDeleteAll} variant='contained' >Remove All</Button>
                                        </Box>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Box sx={{ p: 2 }}>
                                                    <TableContainer component={Paper}>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Facility Name</TableCell>
                                                                    <TableCell>Facility Type</TableCell>
                                                                    <TableCell>Facility Price</TableCell>
                                                                    <TableCell>Action</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {AllFacilities?.data?.map((row) => (
                                                                    <TableRow key={row.id}>
                                                                        <TableCell>{row?.title}</TableCell>
                                                                        <TableCell>{row?.isPaid === true ? 'Paid Facility' : 'Free Facility'}</TableCell>
                                                                        <TableCell>{row?.price}</TableCell>
                                                                        <TableCell><Button onClick={() => handleFacilitiesDelete(row?._id)} variant='contained' >Remove</Button></TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                    }
                </Grid>
            ) : (
                <Grid container spacing={2}>
                    {renderTabs()}
                </Grid>
            )}

            <GlobalModel
                open={addPropertyType}
                onClose={handleAddpropertytype}
                content={<Fade in={addPropertyType}>
                    <Box sx={style}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        label="Title"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                        name="title"
                                        value={formData.title}
                                        onChange={handleChange}
                                    />

                                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-around'}>
                                        <Typography component="legend">Select Rating</Typography>
                                        <Rating
                                            name="ratings"
                                            size="large"
                                            value={formData.ratings}
                                            onChange={(event, newValue) => handleRatingChange(newValue)}
                                        />
                                        <input
                                            type="file"
                                            required
                                            name="cover"
                                            onChange={(e) => dispatch(GetFileUploaded(e, currentUser._id))}
                                        />

                                    </Box>
                                    <TextField
                                        label="Description"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        required
                                        name="discription"
                                        value={formData.discription}
                                        onChange={handleChange}
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', placeItems: 'center', justifyContent: 'end', gap: 4 }}>
                                <Button onClick={handleAddpropertytype} size='sm' variant='outlined'>
                                    Cancel
                                </Button>
                                <Button type='submit' onClick={handleSubmit} size='sm' variant='contained'>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>}
            />

            <GlobalModel
                open={updatePropertyType}
                onClose={handleClosepropertyType}
                content={<Fade in={updatePropertyType}>
                    <Box sx={style}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>

                                <form onSubmit={handleUpdateProperty}>
                                    <TextField
                                        label="Title"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                        name="title"
                                        value={updateProperyData.title}
                                        onChange={(e) => handleUpdateData(e.target.name, e.target.value)}
                                    />

                                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-around'}>
                                        <Typography component="legend">Select Rating</Typography>
                                        <Rating
                                            name="ratings"
                                            size="large"
                                            value={updateProperyData.ratings}
                                            onChange={(e, newValue) => handleUpdateData("ratings", newValue)}
                                        />
                                        <input
                                            type="file"
                                            required
                                            name="cover"
                                            onChange={(e) => dispatch(GetFileUploaded(e, currentUser._id))}
                                        />

                                    </Box>
                                    <TextField
                                        label="Description"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        required
                                        name="discription"
                                        value={updateProperyData.discription}
                                        onChange={(e) => handleUpdateData(e.target.name, e.target.value)}
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', placeItems: 'center', justifyContent: 'end', gap: 4 }}>
                                <Button onClick={handleClosepropertyType} size='sm' variant='outlined'>
                                    Cancel
                                </Button>
                                <Button onClick={handleUpdateProperty} size='sm' variant='contained'>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>}
            />
        </div>
    );
};

export default ManageRoomAmenities;
